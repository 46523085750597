// sidebarReducer.js
import { TOGGLE_SIDEBAR } from './sidebarActions';

const initialState = {
  sidebarEnabled: false, // Set sidebarEnabled to false by default
  sidebarAnimEnabled: true
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarEnabled: !state.sidebarEnabled
      };
    default:
      return state;
  }
};

export default sidebarReducer;
